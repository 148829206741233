import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

export default function AdminRoute({ component: Component, role, ...rest }) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <Route {...rest} render={(props) =>
        userInfo && userInfo.role && (userInfo.role.bIsAdmin || userInfo.role.bIsSupport || userInfo.role.permissions[role]) && (
          <Component {...props}></Component>
        )
      }
    />
  );
}
