export const CHARACTER_DETAILS_REQUEST = 'CHARACTER_DETAILS_REQUEST';
export const CHARACTER_DETAILS_SUCCESS = 'CHARACTER_DETAILS_SUCCESS';
export const CHARACTER_DETAILS_FAIL = 'CHARACTER_DETAILS_FAIL';
export const CHARACTER_DETAILS_RESET = 'CHARACTER_DETAILS_RESET';

export const CHARACTER_LIST_REQUEST = 'CHARACTER_LIST_REQUEST';
export const CHARACTER_LIST_SUCCESS = 'CHARACTER_LIST_SUCCESS';
export const CHARACTER_LIST_FAIL = 'CHARACTER_LIST_FAIL';

export const MYTHIC_LIST_REQUEST = 'MYTHIC_LIST_REQUEST';
export const MYTHIC_LIST_SUCCESS = 'MYTHIC_LIST_SUCCESS';
export const MYTHIC_LIST_FAIL = 'MYTHIC_LIST_FAIL';

export const CHARACTER_UPDATE_PROFILE_REQUEST = 'CHARACTER_UPDATE_PROFILE_REQUEST';
export const CHARACTER_UPDATE_PROFILE_SUCCESS = 'CHARACTER_UPDATE_PROFILE_SUCCESS';
export const CHARACTER_UPDATE_PROFILE_FAIL = 'CHARACTER_UPDATE_PROFILE_FAIL';
export const CHARACTER_UPDATE_PROFILE_RESET = 'CHARACTER_UPDATE_PROFILE_RESET';

export const CHARACTER_UPDATE_REQUEST = 'CHARACTER_UPDATE_REQUEST';
export const CHARACTER_UPDATE_SUCCESS = 'CHARACTER_UPDATE_SUCCESS';
export const CHARACTER_UPDATE_FAIL = 'CHARACTER_UPDATE_FAIL';
export const CHARACTER_UPDATE_RESET = 'CHARACTER_UPDATE_RESET';

export const CHARACTER_DELETE_REQUEST = 'CHARACTER_DELETE_REQUEST';
export const CHARACTER_DELETE_SUCCESS = 'CHARACTER_DELETE_SUCCESS';
export const CHARACTER_DELETE_FAIL = 'CHARACTER_DELETE_FAIL';
export const CHARACTER_DELETE_RESET = 'CHARACTER_DELETE_RESET';
