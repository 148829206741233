export const GUILD_LIST_REQUEST = 'GUILD_LIST_REQUEST';
export const GUILD_LIST_SUCCESS = 'GUILD_LIST_SUCCESS';
export const GUILD_LIST_FAIL = 'GUILD_LIST_FAIL';

export const GUILDSIEGE_LOG_REQUEST = 'GUILDSIEGE_LOG_REQUEST';
export const GUILDSIEGE_LOG_SUCCESS = 'GUILDSIEGE_LOG_SUCCESS';
export const GUILDSIEGE_LOG_FAIL = 'GUILDSIEGE_LOG_FAIL';

export const GUILDWAR_LOG_REQUEST = 'GUILDWAR_LOG_REQUEST';
export const GUILDWAR_LOG_SUCCESS = 'GUILDWAR_LOG_SUCCESS';
export const GUILDWAR_LOG_FAIL = 'GUILDWAR_LOG_FAIL';

export const GUILD_DETAILS_REQUEST = 'GUILD_DETAILS_REQUEST';
export const GUILD_DETAILS_SUCCESS = 'GUILD_DETAILS_SUCCESS';
export const GUILD_DETAILS_FAIL = 'GUILD_DETAILS_FAIL';
export const GUILD_DETAILS_RESET = 'GUILD_DETAILS_RESET';
