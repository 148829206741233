import {
  SAVE_REFRESH_TOKEN,
  SAVE_ACCESS_TOKEN,
  SAVE_ACCESS_TOKEN_EXP,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAIL,
} from '../constants/tokenConstants';
import {
  USER_SIGNIN_SUCCESS,
} from '../constants/userConstants';
import { signout } from '../actions/userActions';
import jwt from 'jsonwebtoken';
import Axios from '../axiosinstance';

export const updateAccessToken = (data) => (dispatch) => {
  dispatch({ type: SAVE_ACCESS_TOKEN, payload: data });
  //localStorage.setItem('accessToken', JSON.stringify(data));
};

export const updateRefreshToken = (data) => (dispatch) => {
  dispatch({ type: SAVE_REFRESH_TOKEN, payload: data });
  localStorage.setItem('tok', JSON.stringify(data));
};

export const updateAccessTokenExp = (data) => (dispatch) => {
  dispatch({ type: SAVE_ACCESS_TOKEN_EXP, payload: data });
};

export const checkValidAccessToken = (forceCheck = false) => async (dispatch, getState) => {
  const { accessTokenExp: { accessTokenExpInfo }, } = getState();
  const now = new Date().getTime();
  if((now >= accessTokenExpInfo - 10000 && accessTokenExpInfo) || accessTokenExpInfo === null || forceCheck) {
    const { refreshToken: { refreshTokenInfo }, } = getState();
    if(refreshTokenInfo) {
      await Axios.get('/api/users/authenticate', {
        headers: { refresh: `Bearer ${refreshTokenInfo}` },
      }).then((res)=>{
        if(res.status === 200) {
          const token = jwt.decode(res.data.accessToken);
          
          dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
          dispatch(updateAccessToken(res.data.accessToken));
          dispatch(updateAccessTokenExp(now + token.expCount));
          dispatch(updateRefreshToken(res.data.refreshToken));
        }
      }).catch(e => {
        dispatch(signout());
      });
    }
  }
  return true;
}

export const isAuthorized = () => async (dispatch, getState) => {
  try {
    await dispatch(checkValidAccessToken());
    const { accessToken: { accessTokenInfo }, } = getState();
      if(accessTokenInfo) {
      await Axios.get('/api/users/authorized', {
        headers: { admin: `Bearer ${accessTokenInfo}` },
      }).then((res)=>{
        
      }).catch(error => {
        dispatch(signout());
      });
    }
  } catch (error) {
    console.error('ERROR: ', error);
  }
};

export const verifyTokenReq = (verifyToken) => async (dispatch, getState) => {
  dispatch({ type: VERIFY_TOKEN_REQUEST });
  try {
    const { data } = await Axios.get('/api/users/verify', {
      headers: { verify: `Bearer ${verifyToken}` },
    });

    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));
    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));   

    dispatch({ type: VERIFY_TOKEN_SUCCESS, payload: data.message });
  } catch (error) {
    dispatch({type: VERIFY_TOKEN_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message });
  }
};