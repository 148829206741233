export const REDEEM_LIST_REQUEST = 'REDEEM_LIST_REQUEST';
export const REDEEM_LIST_SUCCESS = 'REDEEM_LIST_SUCCESS';
export const REDEEM_LIST_FAIL = 'REDEEM_LIST_FAIL';

export const REDEEM_DETAILS_REQUEST = 'REDEEM_DETAILS_REQUEST';
export const REDEEM_DETAILS_SUCCESS = 'REDEEM_DETAILS_SUCCESS';
export const REDEEM_DETAILS_FAIL = 'REDEEM_DETAILS_FAIL';

export const REDEEM_CREATE_REQUEST = 'REDEEM_CREATE_REQUEST';
export const REDEEM_CREATE_SUCCESS = 'REDEEM_CREATE_SUCCESS';
export const REDEEM_CREATE_FAIL = 'REDEEM_CREATE_FAIL';
export const REDEEM_CREATE_RESET = 'REDEEM_CREATE_RESET';

export const REDEEM_UPDATE_REQUEST = 'REDEEM_UPDATE_REQUEST';
export const REDEEM_UPDATE_SUCCESS = 'REDEEM_UPDATE_SUCCESS';
export const REDEEM_UPDATE_FAIL = 'REDEEM_UPDATE_FAIL';
export const REDEEM_UPDATE_RESET = 'REDEEM_UPDATE_RESET';

export const REDEEM_DELETE_REQUEST = 'REDEEM_DELETE_REQUEST';
export const REDEEM_DELETE_SUCCESS = 'REDEEM_DELETE_SUCCESS';
export const REDEEM_DELETE_FAIL = 'REDEEM_DELETE_FAIL';
export const REDEEM_DELETE_RESET = 'REDEEM_DELETE_RESET';
