import {  
  ACCOUNT_MAINTENANCE_FAIL,
  ACCOUNT_MAINTENANCE_REQUEST,
  ACCOUNT_MAINTENANCE_RESET,
  ACCOUNT_MAINTENANCE_SUCCESS,
  ACCOUNT_EDIT_REQUEST,
  ACCOUNT_EDIT_SUCCESS,
  ACCOUNT_EDIT_FAIL,
  ACCOUNT_EDIT_RESET,
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_LIST_FAIL,
  ACCOUNT_GET_REQUEST,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_FAIL,
  ACCOUNT_GET_RESET,
} from '../constants/flyffAccountConstants';

export const accountMaintenanceReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_MAINTENANCE_REQUEST:
      return { loading: true };
    case ACCOUNT_MAINTENANCE_SUCCESS:
      return { loading: false, success: true };
    case ACCOUNT_MAINTENANCE_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_MAINTENANCE_RESET:
      return {};
    default:
      return state;
  }
};

export const accountListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_REQUEST:
      return { loading: true };
    case ACCOUNT_LIST_SUCCESS:
      return { loading: false, accounts: action.payload.accounts, pages: action.payload.pages, page: action.payload.page};
    case ACCOUNT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const accountEditReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_EDIT_REQUEST:
      return { loading: true };
    case ACCOUNT_EDIT_SUCCESS:
      return { loading: false, success: true };
    case ACCOUNT_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const accountGetReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_GET_REQUEST:
      return { loading: true };
    case ACCOUNT_GET_SUCCESS:
      return { loading: false, success: true, accounts: action.payload };
    case ACCOUNT_GET_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_GET_RESET:
      return {};
    default:
      return state;
  }
};




