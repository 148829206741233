import {
  GUILDSIEGE_LOG_FAIL,
  GUILDSIEGE_LOG_REQUEST,
  GUILDSIEGE_LOG_SUCCESS,
  GUILDWAR_LOG_FAIL,
  GUILDWAR_LOG_REQUEST,
  GUILDWAR_LOG_SUCCESS,
  GUILD_DETAILS_FAIL,
  GUILD_DETAILS_REQUEST,
  GUILD_DETAILS_RESET,
  GUILD_DETAILS_SUCCESS,
  GUILD_LIST_FAIL,
  GUILD_LIST_REQUEST,
  GUILD_LIST_SUCCESS,
} from '../constants/flyffGuildConstants';

export const guildListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GUILD_LIST_REQUEST:
      return { loading: true };
    case GUILD_LIST_SUCCESS:
      return { loading: false, guilds: action.payload.guilds, pages: action.payload.pages, page: action.payload.page};
    case GUILD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const guildWarReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GUILDWAR_LOG_REQUEST:
      return { loading: true };
    case GUILDWAR_LOG_SUCCESS:
      return { loading: false, guildwar: action.payload.guildwar, pages: action.payload.pages, page: action.payload.page};
    case GUILDWAR_LOG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const gsLogReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GUILDSIEGE_LOG_REQUEST:
      return { loading: true };
    case GUILDSIEGE_LOG_SUCCESS:
      return { loading: false, log: action.payload.log, pages: action.payload.pages, page: action.payload.page};
    case GUILDSIEGE_LOG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const guildDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GUILD_DETAILS_REQUEST:
      return { loading: true };
    case GUILD_DETAILS_SUCCESS:
      return { loading: false, guild: action.payload };
    case GUILD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case GUILD_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};