import {
  MILESTINE_GET_FAIL,
  MILESTINE_GET_REQUEST,
  MILESTINE_GET_RESET,
  MILESTINE_GET_SUCCESS,
} from '../constants/milestoneConstants';

export const milestoneClaimReducer = (state = {}, action) => {
  switch (action.type) {
    case MILESTINE_GET_REQUEST:
      return { loading: true };
    case MILESTINE_GET_SUCCESS:
      return { loading: false, success: true, milestone: action.payload };
    case MILESTINE_GET_FAIL:
      return { loading: false, error: action.payload };
    case MILESTINE_GET_RESET:
      return {};
    default:
      return state;
  }
};