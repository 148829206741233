export const MAIL_LIST_REQUEST = 'MAIL_LIST_REQUEST';
export const MAIL_LIST_SUCCESS = 'MAIL_LIST_SUCCESS';
export const MAIL_LIST_FAIL = 'MAIL_LIST_FAIL';

export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_FAIL = 'BANK_LIST_FAIL';

export const GUILDBANK_LIST_REQUEST = 'GUILDBANK_LIST_REQUEST';
export const GUILDBANK_LIST_SUCCESS = 'GUILDBANK_LIST_SUCCESS';
export const GUILDBANK_LIST_FAIL = 'GUILDBANK_LIST_FAIL';

export const POCKET_LIST_REQUEST = 'POCKET_LIST_REQUEST';
export const POCKET_LIST_SUCCESS = 'POCKET_LIST_SUCCESS';
export const POCKET_LIST_FAIL = 'POCKET_LIST_FAIL';

export const VENDOR_LIST_REQUEST = 'VENDOR_LIST_REQUEST';
export const VENDOR_LIST_SUCCESS = 'VENDOR_LIST_SUCCESS';
export const VENDOR_LIST_FAIL = 'VENDOR_LIST_FAIL';

export const AUCTION_LIST_REQUEST = 'AUCTION_LIST_REQUEST';
export const AUCTION_LIST_SUCCESS = 'AUCTION_LIST_SUCCESS';
export const AUCTION_LIST_FAIL = 'AUCTION_LIST_FAIL';

export const TRADES_LIST_REQUEST = 'TRADES_LIST_REQUEST';
export const TRADES_LIST_SUCCESS = 'TRADES_LIST_SUCCESS';
export const TRADES_LIST_FAIL = 'TRADES_LIST_FAIL';

export const ITEMLOOKUP_LIST_REQUEST = 'ITEMLOOKUP_LIST_REQUEST';
export const ITEMLOOKUP_LIST_SUCCESS = 'ITEMLOOKUP_LIST_SUCCESS';
export const ITEMLOOKUP_LIST_FAIL = 'ITEMLOOKUP_LIST_FAIL';
