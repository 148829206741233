import {  
  WIKI_CAT_LIST_REQUEST,
  WIKI_CAT_LIST_SUCCESS,
  WIKI_CAT_LIST_FAIL,
  WIKI_CAT_DETAILS_REQUEST,
  WIKI_CAT_DETAILS_SUCCESS,
  WIKI_CAT_DETAILS_FAIL,
  WIKI_CAT_CREATE_REQUEST,
  WIKI_CAT_CREATE_SUCCESS,
  WIKI_CAT_CREATE_FAIL,
  WIKI_CAT_CREATE_RESET,
  WIKI_CAT_UPDATE_REQUEST,
  WIKI_CAT_UPDATE_SUCCESS,
  WIKI_CAT_UPDATE_FAIL,
  WIKI_CAT_UPDATE_RESET,
  WIKI_CAT_DELETE_REQUEST,
  WIKI_CAT_DELETE_SUCCESS,
  WIKI_CAT_DELETE_FAIL,
  WIKI_CAT_DELETE_RESET,
  WIKI_ART_LIST_REQUEST,
  WIKI_ART_LIST_SUCCESS,
  WIKI_ART_LIST_FAIL,
  WIKI_ART_DETAILS_REQUEST,
  WIKI_ART_DETAILS_SUCCESS,
  WIKI_ART_DETAILS_FAIL,
  WIKI_ART_CREATE_REQUEST,
  WIKI_ART_CREATE_SUCCESS,
  WIKI_ART_CREATE_FAIL,
  WIKI_ART_CREATE_RESET,
  WIKI_ART_UPDATE_REQUEST,
  WIKI_ART_UPDATE_SUCCESS,
  WIKI_ART_UPDATE_FAIL,
  WIKI_ART_UPDATE_RESET,
  WIKI_ART_DELETE_REQUEST,
  WIKI_ART_DELETE_SUCCESS,
  WIKI_ART_DELETE_FAIL,
  WIKI_ART_DELETE_RESET,
  WIKI_REVIEW_CREATE_REQUEST,
  WIKI_REVIEW_CREATE_SUCCESS,
  WIKI_REVIEW_CREATE_FAIL,
  WIKI_REVIEW_CREATE_RESET,
} from '../constants/wikiConstants';

export const wikiCatReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_CAT_LIST_REQUEST:
      return { loading: true };
    case WIKI_CAT_LIST_SUCCESS:
      return { loading: false, categorys: action.payload.category, page: action.payload.page, pages: action.payload.pages, req: action.payload.req };
    case WIKI_CAT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wikiCatDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case WIKI_CAT_DETAILS_REQUEST:
      return { loading: true };
    case WIKI_CAT_DETAILS_SUCCESS:
      return { loading: false, category: action.payload };
    case WIKI_CAT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wikiCatCreateReducer = (state = {}, action) => {
  switch (action.type) {

    case WIKI_CAT_CREATE_REQUEST:
      return { loading: true };
    case WIKI_CAT_CREATE_SUCCESS:
      return { loading: false, success: true, category: action.payload };
    case WIKI_CAT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_CAT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiCatUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_CAT_UPDATE_REQUEST:
      return { loading: true };
    case WIKI_CAT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case WIKI_CAT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_CAT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiCatDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_CAT_DELETE_REQUEST:
      return { loading: true };
    case WIKI_CAT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case WIKI_CAT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_CAT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiArtReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_ART_LIST_REQUEST:
      return { loading: true };
    case WIKI_ART_LIST_SUCCESS:
      return { loading: false, articles: action.payload.article, page: action.payload.page, pages: action.payload.pages, req: action.payload.req };
    case WIKI_ART_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wikiArtDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case WIKI_ART_DETAILS_REQUEST:
      return { loading: true };
    case WIKI_ART_DETAILS_SUCCESS:
      return { loading: false, article: action.payload };
    case WIKI_ART_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wikiArtCreateReducer = (state = {}, action) => {
  switch (action.type) {

    case WIKI_ART_CREATE_REQUEST:
      return { loading: true };
    case WIKI_ART_CREATE_SUCCESS:
      return { loading: false, success: true, article: action.payload };
    case WIKI_ART_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_ART_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiArtUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_ART_UPDATE_REQUEST:
      return { loading: true };
    case WIKI_ART_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case WIKI_ART_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_ART_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiArtDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_ART_DELETE_REQUEST:
      return { loading: true };
    case WIKI_ART_DELETE_SUCCESS:
      return { loading: false, success: true };
    case WIKI_ART_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_ART_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const wikiReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WIKI_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case WIKI_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true, article: action.payload };
    case WIKI_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WIKI_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};