import {  
  DOWNLOAD_LIST_REQUEST,
  DOWNLOAD_LIST_SUCCESS,
  DOWNLOAD_LIST_FAIL,
  DOWNLOAD_DETAILS_REQUEST,
  DOWNLOAD_DETAILS_SUCCESS,
  DOWNLOAD_DETAILS_FAIL,
  DOWNLOAD_CREATE_REQUEST,
  DOWNLOAD_CREATE_SUCCESS,
  DOWNLOAD_CREATE_FAIL,
  DOWNLOAD_CREATE_RESET,
  DOWNLOAD_UPDATE_REQUEST,
  DOWNLOAD_UPDATE_SUCCESS,
  DOWNLOAD_UPDATE_FAIL,
  DOWNLOAD_UPDATE_RESET,
  DOWNLOAD_DELETE_REQUEST,
  DOWNLOAD_DELETE_SUCCESS,
  DOWNLOAD_DELETE_FAIL,
  DOWNLOAD_DELETE_RESET
} from '../constants/downloadConstants';

export const downloadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_LIST_REQUEST:
      return { loading: true };
    case DOWNLOAD_LIST_SUCCESS:
      return { loading: false, downloads: action.payload.download, page: action.payload.page, pages: action.payload.pages, req: action.payload.req };
    case DOWNLOAD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const downloadDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case DOWNLOAD_DETAILS_REQUEST:
      return { loading: true };
    case DOWNLOAD_DETAILS_SUCCESS:
      return { loading: false, download: action.payload };
    case DOWNLOAD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const downloadCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_CREATE_REQUEST:
      return { loading: true };
    case DOWNLOAD_CREATE_SUCCESS:
      return { loading: false, success: true, download: action.payload };
    case DOWNLOAD_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const downloadUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_UPDATE_REQUEST:
      return { loading: true };
    case DOWNLOAD_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DOWNLOAD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const downloadDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_DELETE_REQUEST:
      return { loading: true };
    case DOWNLOAD_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DOWNLOAD_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case DOWNLOAD_DELETE_RESET:
      return {};
    default:
      return state;
  }
};