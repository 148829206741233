import {
  SAVE_HIDEOVERLAY,
  SAVE_COOKIEAGREE,
  SAVE_FIRSTVISIT,
  //SET_MODAL,
} from '../constants/localConstants';

export const hideOverlayReducer = (state = { hideOverlay: [] }, action) => {
  switch (action.type) {
    case SAVE_HIDEOVERLAY:
      return { ...state, overlayInfo: action.payload };
    default:
      return state;
  }
};

export const cookieAgreeReducer = (state = { cookieAgree: [] }, action) => {
  switch (action.type) {
    case SAVE_COOKIEAGREE:
      return { ...state, cookieInfo: action.payload };
    default:
      return state;
  }
};

export const firstVisitReducer = (state = { firstVisit: [] }, action) => {
  switch (action.type) {
    case SAVE_FIRSTVISIT:
      return { ...state, visitInfo: action.payload };
    default:
      return state;
  }
};

/*export const setModalReducer = (state = { modalBg: [] }, action) => {
  switch (action.type) {
    case SET_MODAL: return { ...state, modalInfo: action.payload };
    default: return state;
  }
};*/
