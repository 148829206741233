import {
  SAVE_ACCESS_TOKEN,
  SAVE_REFRESH_TOKEN,
  DELETE_REFRESH_TOKEN,
  DELETE_ACCESS_TOKEN,
  SAVE_ACCESS_TOKEN_EXP,
  VERIFY_TOKEN_FAIL,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
} from '../constants/tokenConstants';

export const refreshTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_REFRESH_TOKEN:
      return { ...state, refreshTokenInfo: action.payload };
    case DELETE_REFRESH_TOKEN:
      return {};
    default:
      return state;
  }
};

export const accessTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_ACCESS_TOKEN:
      return { ...state, accessTokenInfo: action.payload };
    case DELETE_ACCESS_TOKEN:
      return {};
    default:
      return state;
  }
};

export const accessTokenExpReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_ACCESS_TOKEN_EXP:
      return { ...state, accessTokenExpInfo: action.payload };
    default:
      return state;
  }
};

export const verifyTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_TOKEN_REQUEST:
      return { loading: true };
    case VERIFY_TOKEN_SUCCESS:
      return { loading: false, success: action.payload};
    case VERIFY_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
