import Axios from '../axiosinstance';
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_NOTIFICATION_REQUEST,
  USER_UPDATE_NOTIFICATION_SUCCESS,
  USER_UPDATE_NOTIFICATION_FAIL,
  RESENDMAIL_FAIL,
  RESENDMAIL_SUCCESS,
  RESENDMAIL_REQUEST,
  REDEEMCODE_FAIL,
  REDEEMCODE_SUCCESS,
  REDEEMCODE_REQUEST,
} from '../constants/userConstants';
import jwt from 'jsonwebtoken';
import {
  DELETE_REFRESH_TOKEN,
  DELETE_ACCESS_TOKEN,
} from '../constants/tokenConstants';
import { updateAccessToken, updateRefreshToken, updateAccessTokenExp, checkValidAccessToken } from '../actions/tokenActions';

export const register = (recaptcha, name, email, password, birthday, birthmonth, birthyear) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post('/api/users/register', {
      recaptcha, name, email, password, birthday, birthmonth, birthyear
    });    
    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: token });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));

    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));
  } catch (error) {
    dispatch({type: USER_REGISTER_FAIL, payload: error.response && error.response.data.message ? error.response.data : error.message});
  }
};

export const signin = (recaptcha, email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post('/api/users/signin', { recaptcha, email, password });

    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));
    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));   
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message});
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem('tok');
  //localStorage.removeItem('accessToken');

  localStorage.removeItem('cartItems');
  localStorage.removeItem('shippingAddress');
  dispatch({ type: USER_SIGNOUT });

  dispatch({ type: DELETE_REFRESH_TOKEN });
  dispatch({ type: DELETE_ACCESS_TOKEN });
  //document.location.href = '/signin';
};
export const detailsUser = (userId, roles) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.get(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${accessTokenInfo}`, Roles: roles},
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.put(`/api/users/profile`, user, {
      headers: { Authorization: `Bearer ${accessTokenInfo}` },
    });

    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));

    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const sendCurrencyToCharacter = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.put(`/api/users/sendcurrency`, user, {
      headers: { Authorization: `Bearer ${accessTokenInfo}` },
    });

    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));

    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const resendMailUser = (recaptcha) => async (dispatch, getState) => {
  dispatch({ type: RESENDMAIL_REQUEST });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.post(`/api/users/resend`, recaptcha, {
      headers: { Authorization: `Bearer ${accessTokenInfo}` },
    });

    dispatch({ type: RESENDMAIL_SUCCESS, payload: data.message });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({ type: RESENDMAIL_FAIL, payload: message });
  }
};

export const sendRedeemCodeUser = (user) => async (dispatch, getState) => {
  dispatch({ type: REDEEMCODE_REQUEST });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.post(`/api/users/redeem`, user, {
      headers: { Authorization: `Bearer ${accessTokenInfo}` },
    });

    const token = jwt.decode(data.accessToken);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
    dispatch({ type: REDEEMCODE_SUCCESS, payload: data.message });
    dispatch(updateAccessToken(data.accessToken));
    dispatch(updateRefreshToken(data.refreshToken));

    dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));

  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    dispatch({ type: REDEEMCODE_FAIL, payload: message });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_REQUEST, payload: user });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.put(`/api/users/${user._id}`, user, {
      headers: { Authorization: `Bearer ${accessTokenInfo}` },
    });
    dispatch({ type: USER_UPDATE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
  }
};

export const updateNotifications = () => async (dispatch, getState) => {
  const now = Date.now();
  const eAt = localStorage.getItem('notification_eAt');
  if(!eAt || (eAt && (now - eAt >= 10000)))
  {
    localStorage.setItem('notification_eAt', JSON.stringify(now));
    dispatch({ type: USER_UPDATE_NOTIFICATION_REQUEST});
    await dispatch(checkValidAccessToken());
    const { accessToken: { accessTokenInfo }, } = getState();
    try {
      const { data } = await Axios.get(`/api/users/notifications`, {
        headers: { Authorization: `Bearer ${accessTokenInfo}` },
      });
      const token = jwt.decode(data.accessToken);
  
      dispatch({ type: USER_UPDATE_NOTIFICATION_SUCCESS });
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: token });
      dispatch(updateAccessToken(data.accessToken));
      dispatch(updateRefreshToken(data.refreshToken));
      
      dispatch(updateAccessTokenExp(new Date().getTime() + token.expCount));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_UPDATE_NOTIFICATION_FAIL, payload: message });
    }
  }
};

export const listUsers = ({ pageNumber = '', searchBy = 0, name = '', role = 0}) => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.get(`/api/users?pageNumber=${pageNumber}&searchBy=${searchBy}&name=${name}&role=${role}`, {
      headers: { Authorization: `Bearer ${accessTokenInfo}`, },
    });
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
  }
};
export const deleteUser = (userId, del) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  await dispatch(checkValidAccessToken());
  const { accessToken: { accessTokenInfo }, } = getState();
  try {
    const { data } = await Axios.delete(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${accessTokenInfo}`, Delete: del},
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
  }
};
/*export const listTopSellers = () => async (dispatch) => {
  dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  try {
    const { data } = await Axios.get('/api/users/top-sellers');
    dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_TOPSELLERS_LIST_FAIL, payload: message });
  }
};*/
