const {
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_DETAILS_FAIL,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_CREATE_FAIL,
  NEWS_CREATE_RESET,
  NEWS_UPDATE_REQUEST,
  NEWS_UPDATE_SUCCESS,
  NEWS_UPDATE_FAIL,
  NEWS_UPDATE_RESET,
  NEWS_DELETE_REQUEST,
  NEWS_DELETE_SUCCESS,
  NEWS_DELETE_FAIL,
  NEWS_DELETE_RESET,
  NEWS_REVIEW_CREATE_REQUEST,
  NEWS_REVIEW_CREATE_SUCCESS,
  NEWS_REVIEW_CREATE_FAIL,
  NEWS_REVIEW_CREATE_RESET,
} = require('../constants/newsConstants');

export const newsListReducer = (
  state = { loading: true, news: [] },
  action
) => {
  switch (action.type) {
    case NEWS_LIST_REQUEST:
      return { loading: true };
    case NEWS_LIST_SUCCESS:
      return {
        loading: false,
        news: action.payload.news,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case NEWS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newsDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case NEWS_DETAILS_REQUEST:
      return { loading: true };
    case NEWS_DETAILS_SUCCESS:
      return { loading: false, news: action.payload };
    case NEWS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const newsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_CREATE_REQUEST:
      return { loading: true };
    case NEWS_CREATE_SUCCESS:
      return { loading: false, success: true, news: action.payload };
    case NEWS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case NEWS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
export const newsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_UPDATE_REQUEST:
      return { loading: true };
    case NEWS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case NEWS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case NEWS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
export const newsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_DELETE_REQUEST:
      return { loading: true };
    case NEWS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case NEWS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case NEWS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
export const newsReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case NEWS_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case NEWS_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case NEWS_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
