import {
  SAVE_HIDEOVERLAY,
  SAVE_COOKIEAGREE,
  SAVE_FIRSTVISIT,
} from '../constants/localConstants';

export const saveHideOverlay = (data) => (dispatch) => {
  dispatch({ type: SAVE_HIDEOVERLAY, payload: data });
  localStorage.setItem('hideOverlay', JSON.stringify(data));
};

export const saveCookieAgree = (data) => (dispatch) => {
  dispatch({ type: SAVE_COOKIEAGREE, payload: data });
  localStorage.setItem('cookieAgree', JSON.stringify(data));
};

export const saveFirstVisit = (data) => (dispatch) => {
  dispatch({ type: SAVE_FIRSTVISIT, payload: data });
  localStorage.setItem('firstVisit', JSON.stringify(data));
};


/*export const setModalApp = (data) => (dispatch) => {
  dispatch({ type: SET_MODAL, payload: data });
};*/
