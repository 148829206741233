import { AUCTION_LIST_FAIL, AUCTION_LIST_REQUEST, AUCTION_LIST_SUCCESS, BANK_LIST_FAIL, BANK_LIST_REQUEST, BANK_LIST_SUCCESS, GUILDBANK_LIST_FAIL, GUILDBANK_LIST_REQUEST, GUILDBANK_LIST_SUCCESS, ITEMLOOKUP_LIST_FAIL, ITEMLOOKUP_LIST_REQUEST, ITEMLOOKUP_LIST_SUCCESS, MAIL_LIST_FAIL, MAIL_LIST_REQUEST, MAIL_LIST_SUCCESS, POCKET_LIST_FAIL, POCKET_LIST_REQUEST, POCKET_LIST_SUCCESS, TRADES_LIST_FAIL, TRADES_LIST_REQUEST, TRADES_LIST_SUCCESS, VENDOR_LIST_FAIL, VENDOR_LIST_REQUEST, VENDOR_LIST_SUCCESS } from '../constants/flyffMiscConstants';

export const mailsListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case MAIL_LIST_REQUEST:
      return { loading: true };
    case MAIL_LIST_SUCCESS:
      return { loading: false, mails: action.payload.mails, pages: action.payload.pages, page: action.payload.page};
    case MAIL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const banksListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case BANK_LIST_REQUEST:
      return { loading: true };
    case BANK_LIST_SUCCESS:
      return { loading: false, banks: action.payload.banks, pages: action.payload.pages, page: action.payload.page};
    case BANK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const guildbanksListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case GUILDBANK_LIST_REQUEST:
      return { loading: true };
    case GUILDBANK_LIST_SUCCESS:
      return { loading: false, guildbanks: action.payload.guildbanks, pages: action.payload.pages, page: action.payload.page};
    case GUILDBANK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pocketsListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case POCKET_LIST_REQUEST:
      return { loading: true };
    case POCKET_LIST_SUCCESS:
      return { loading: false, pockets: action.payload.pockets, pages: action.payload.pages, page: action.payload.page};
    case POCKET_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vendorsListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return { loading: true };
    case VENDOR_LIST_SUCCESS:
      return { loading: false, vendors: action.payload.vendors, pages: action.payload.pages, page: action.payload.page};
    case VENDOR_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const auctionsListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case AUCTION_LIST_REQUEST:
      return { loading: true };
    case AUCTION_LIST_SUCCESS:
      return { loading: false, auctions: action.payload.auctions, pages: action.payload.pages, page: action.payload.page};
    case AUCTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tradesListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case TRADES_LIST_REQUEST:
      return { loading: true };
    case TRADES_LIST_SUCCESS:
      return { loading: false, trades: action.payload.trades, pages: action.payload.pages, page: action.payload.page};
    case TRADES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const itemlookupListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ITEMLOOKUP_LIST_REQUEST:
      return { loading: true };
    case ITEMLOOKUP_LIST_SUCCESS:
      return { loading: false, lookup: action.payload.lookup, sizes: action.payload.sizes, pages: action.payload.pages, page: action.payload.page};
    case ITEMLOOKUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};