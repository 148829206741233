export const DOWNLOAD_LIST_REQUEST = 'DOWNLOAD_LIST_REQUEST';
export const DOWNLOAD_LIST_SUCCESS = 'DOWNLOAD_LIST_SUCCESS';
export const DOWNLOAD_LIST_FAIL = 'DOWNLOAD_LIST_FAIL';

export const DOWNLOAD_DETAILS_REQUEST = 'DOWNLOAD_DETAILS_REQUEST';
export const DOWNLOAD_DETAILS_SUCCESS = 'DOWNLOAD_DETAILS_SUCCESS';
export const DOWNLOAD_DETAILS_FAIL = 'DOWNLOAD_DETAILS_FAIL';
export const DOWNLOAD_DETAILS_RESET = 'DOWNLOAD_DETAILS_RESET';

export const DOWNLOAD_CREATE_REQUEST = 'DOWNLOAD_CREATE_REQUEST';
export const DOWNLOAD_CREATE_SUCCESS = 'DOWNLOAD_CREATE_SUCCESS';
export const DOWNLOAD_CREATE_FAIL = 'DOWNLOAD_CREATE_FAIL';
export const DOWNLOAD_CREATE_RESET = 'DOWNLOAD_CREATE_RESET';

export const DOWNLOAD_UPDATE_REQUEST = 'DOWNLOAD_UPDATE_REQUEST';
export const DOWNLOAD_UPDATE_SUCCESS = 'DOWNLOAD_UPDATE_SUCCESS';
export const DOWNLOAD_UPDATE_FAIL = 'DOWNLOAD_UPDATE_FAIL';
export const DOWNLOAD_UPDATE_RESET = 'DOWNLOAD_UPDATE_RESET';

export const DOWNLOAD_DELETE_REQUEST = 'DOWNLOAD_DELETE_REQUEST';
export const DOWNLOAD_DELETE_SUCCESS = 'DOWNLOAD_DELETE_SUCCESS';
export const DOWNLOAD_DELETE_FAIL = 'DOWNLOAD_DELETE_FAIL';
export const DOWNLOAD_DELETE_RESET = 'DOWNLOAD_DELETE_RESET';