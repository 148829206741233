import { UPLOAD_FILE_FAIL, UPLOAD_FILE_REQUEST, UPLOAD_FILE_RESET, UPLOAD_FILE_SUCCESS } from "../constants/uploadConstants";

export const uploadedFileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return { loading: true };
    case UPLOAD_FILE_SUCCESS:
      return { loading: false, success: true, file: action.payload};
    case UPLOAD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case UPLOAD_FILE_RESET:
      return {};
    default:
      return state;
  }
};