export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST';
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS';
export const ACCOUNT_LIST_FAIL = 'ACCOUNT_LIST_FAIL';

export const ACCOUNT_MAINTENANCE_REQUEST = 'ACCOUNT_MAINTENANCE_REQUEST';
export const ACCOUNT_MAINTENANCE_SUCCESS = 'ACCOUNT_MAINTENANCE_SUCCESS';
export const ACCOUNT_MAINTENANCE_FAIL = 'ACCOUNT_MAINTENANCE_FAIL';
export const ACCOUNT_MAINTENANCE_RESET = 'ACCOUNT_MAINTENANCE_RESET';

export const ACCOUNT_EDIT_REQUEST = 'ACCOUNT_EDIT_REQUEST';
export const ACCOUNT_EDIT_SUCCESS = 'ACCOUNT_EDIT_SUCCESS';
export const ACCOUNT_EDIT_FAIL = 'ACCOUNT_EDIT_FAIL';
export const ACCOUNT_EDIT_RESET = 'ACCOUNT_EDIT_RESET';

export const ACCOUNT_GET_REQUEST = 'ACCOUNT_GET_REQUEST';
export const ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS';
export const ACCOUNT_GET_FAIL = 'ACCOUNT_GET_FAIL';
export const ACCOUNT_GET_RESET = 'ACCOUNT_GET_RESET';





