const {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAIL,
  ROLE_DETAILS_REQUEST,
  ROLE_DETAILS_SUCCESS,
  ROLE_DETAILS_FAIL,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_CREATE_FAIL,
  ROLE_CREATE_RESET,
  ROLE_UPDATE_REQUEST,
  ROLE_UPDATE_SUCCESS,
  ROLE_UPDATE_FAIL,
  ROLE_UPDATE_RESET,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAIL,
  ROLE_DELETE_RESET,
} = require('../constants/roleConstants');

export const roleListReducer = (
  state = { loading: true, role: [] },
  action
) => {
  switch (action.type) {
    case ROLE_LIST_REQUEST:
      return { loading: true };
    case ROLE_LIST_SUCCESS:
      return {
        loading: false,
        role: action.payload.role,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ROLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const roleDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ROLE_DETAILS_REQUEST:
      return { loading: true };
    case ROLE_DETAILS_SUCCESS:
      return { loading: false, role: action.payload };
    case ROLE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const roleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_CREATE_REQUEST:
      return { loading: true };
    case ROLE_CREATE_SUCCESS:
      console.log(action.payload)
      return { loading: false, success: true, role: action.payload };
    case ROLE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ROLE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
export const roleUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_UPDATE_REQUEST:
      return { loading: true };
    case ROLE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ROLE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ROLE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
export const roleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_DELETE_REQUEST:
      return { loading: true };
    case ROLE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ROLE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ROLE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
