const {
  REDEEM_LIST_REQUEST,
  REDEEM_LIST_SUCCESS,
  REDEEM_LIST_FAIL,
  REDEEM_CREATE_REQUEST,
  REDEEM_CREATE_SUCCESS,
  REDEEM_CREATE_FAIL,
  REDEEM_CREATE_RESET,
  REDEEM_UPDATE_REQUEST,
  REDEEM_UPDATE_SUCCESS,
  REDEEM_UPDATE_FAIL,
  REDEEM_UPDATE_RESET,
  REDEEM_DELETE_REQUEST,
  REDEEM_DELETE_SUCCESS,
  REDEEM_DELETE_FAIL,
  REDEEM_DELETE_RESET,
} = require('../constants/redeemCodeConstants');

export const redeemCodeListReducer = (
  state = { loading: true, redeemCodes: [] }, action) => {
  switch (action.type) {
    case REDEEM_LIST_REQUEST:
      return { loading: true };
    case REDEEM_LIST_SUCCESS:
      return {
        loading: false,
        redeemCodes: action.payload.redeemCodes,
        pages: action.payload.pages,
        page: action.payload.page,
        req: action.payload.req
      };
    case REDEEM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const redeemCodeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REDEEM_CREATE_REQUEST:
      return { loading: true };
    case REDEEM_CREATE_SUCCESS:
      return { loading: false, success: true, redeemCode: action.payload };
    case REDEEM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REDEEM_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const redeemCodeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case REDEEM_UPDATE_REQUEST:
      return { loading: true };
    case REDEEM_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case REDEEM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REDEEM_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const redeemCodeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REDEEM_DELETE_REQUEST:
      return { loading: true };
    case REDEEM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REDEEM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case REDEEM_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
