import { VOTE_GETLINK_FAIL, VOTE_GETLINK_REQUEST, VOTE_GETLINK_SUCCESS } from "../constants/voteConstants";

export const voteGetLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case VOTE_GETLINK_REQUEST:
      return { loading: true };
    case VOTE_GETLINK_SUCCESS:
      return { loading: false, success: true,
        voteWindow: window.open(action.payload.link, '', 'toolbar=0,status=0,width=483,height=663'),
        nextVote: action.payload.nextVote};
    case VOTE_GETLINK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};