import { PP_VERIFY_FAIL, PP_VERIFY_REQUEST, PP_VERIFY_SUCCESS } from "../constants/paypalConstants";

export const paypalVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case PP_VERIFY_REQUEST:
      return { loading: true };
    case PP_VERIFY_SUCCESS:
      return { loading: false, success: true };
    case PP_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};