import {
  CHARACTER_DELETE_FAIL,
  CHARACTER_DELETE_REQUEST,
  CHARACTER_DELETE_RESET,
  CHARACTER_DELETE_SUCCESS,
  CHARACTER_DETAILS_FAIL,
  CHARACTER_DETAILS_REQUEST,
  CHARACTER_DETAILS_RESET,
  CHARACTER_DETAILS_SUCCESS,
  CHARACTER_LIST_FAIL,
  CHARACTER_LIST_REQUEST,
  CHARACTER_LIST_SUCCESS,
  CHARACTER_UPDATE_FAIL,
  CHARACTER_UPDATE_PROFILE_FAIL,
  CHARACTER_UPDATE_PROFILE_REQUEST,
  CHARACTER_UPDATE_PROFILE_RESET,
  CHARACTER_UPDATE_PROFILE_SUCCESS,
  CHARACTER_UPDATE_REQUEST,
  CHARACTER_UPDATE_RESET,
  CHARACTER_UPDATE_SUCCESS,
  MYTHIC_LIST_FAIL,
  MYTHIC_LIST_REQUEST,
  MYTHIC_LIST_SUCCESS,
} from '../constants/flyffCharacterConstants';

export const characterDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CHARACTER_DETAILS_REQUEST:
      return { loading: true };
    case CHARACTER_DETAILS_SUCCESS:
      return { loading: false, character: action.payload };
    case CHARACTER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CHARACTER_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};
export const characterUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARACTER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case CHARACTER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case CHARACTER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case CHARACTER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};
export const characterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARACTER_UPDATE_REQUEST:
      return { loading: true };
    case CHARACTER_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case CHARACTER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CHARACTER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
export const characterListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CHARACTER_LIST_REQUEST:
      return { loading: true };
    case CHARACTER_LIST_SUCCESS:
      return { loading: false, characters: action.payload.characters, pages: action.payload.pages, page: action.payload.page};
    case CHARACTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mythicListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case MYTHIC_LIST_REQUEST:
      return { loading: true };
    case MYTHIC_LIST_SUCCESS:
      return { loading: false, mythic: action.payload.mythic, pages: action.payload.pages, page: action.payload.page};
    case MYTHIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const characterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARACTER_DELETE_REQUEST:
      return { loading: true };
    case CHARACTER_DELETE_SUCCESS:
      return { loading: false, success: action.payload};
    case CHARACTER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CHARACTER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

