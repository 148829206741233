import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import { checkValidAccessToken } from './actions/tokenActions';

import { saveHideOverlay, saveCookieAgree } from './actions/localActions';

import AdminRoute from './components/AdminRoute';
import PrivateRoute from './components/PrivateRoute';

const AsideScreen = loadable(() => import('./screens/AsideScreen'));
const CartScreen = loadable(() => import('./screens/CartScreen'));
const HomeScreen = loadable(() => import('./screens/HomeScreen'));
const ItemmallScreen = loadable(() => import('./screens/ItemmallScreen'));
const CheckoutVerifyScreen = loadable(() => import('./screens/CheckoutVerifyScreen'));
const DownloadScreen = loadable(() => import('./screens/DownloadScreen'));
//const WikiScreen = loadable(() => import('./screens/WikiScreen'));
const VerifyScreen = loadable(() => import('./screens/VerifyScreen'));
const PrivacypolicyScreen = loadable(() => import('./screens/PrivacypolicyScreen'));
const TosScreen = loadable(() => import('./screens/TosScreen'));
const OrderHistoryScreen = loadable(() => import('./screens/OrderHistoryScreen'));
const OrderScreen = loadable(() => import('./screens/OrderScreen'));
const PaymentMethodScreen = loadable(() => import('./screens/PaymentMethodScreen'));
const PlaceOrderScreen = loadable(() => import('./screens/PlaceOrderScreen'));
const ProductListScreen = loadable(() => import('./screens/ProductListScreen'));
const RedeemListScreen = loadable(() => import('./screens/RedeemListScreen'));
const ProductScreen = loadable(() => import('./screens/ProductScreen'));
const NewsScreen = loadable(() => import('./screens/NewsScreen'));
const AchievementScreen = loadable(() => import('./screens/AchievementScreen'));
const GuildsScreen = loadable(() => import('./screens/GuildsScreen'));
const EloScreen = loadable(() => import('./screens/EloScreen'));
const RankingMythicScreen = loadable(() => import('./screens/RankingMythicScreen'));
const CharacterScreen = loadable(() => import('./screens/CharacterScreen'));
const GuildScreen = loadable(() => import('./screens/GuildScreen'));
const ProfileScreen = loadable(() => import('./screens/ProfileScreen'));
const RegisterScreen = loadable(() => import('./screens/RegisterScreen'));
const ForgottenPasswordScreen = loadable(() => import('./screens/ForgottenPasswordScreen'));
const ForgottenVerifyScreen = loadable(() => import('./screens/ForgottenVerifyScreen'));
const ShippingAddressScreen = loadable(() => import('./screens/ShippingAddressScreen'));
const SigninScreen = loadable(() => import('./screens/SigninScreen'));
const ProductEditScreen = loadable(() => import('./screens/ProductEditScreen'));
const NewsEditScreen = loadable(() => import('./screens/NewsEditScreen'));
const RoleEditScreen = loadable(() => import('./screens/RoleEditScreen'));
const OrderListScreen = loadable(() => import('./screens/OrderListScreen'));
const SellerRoute = loadable(() => import('./components/SellerRoute'));
const SellerScreen = loadable(() => import('./screens/SellerScreen'));
//const MapScreen = loadable(() => import('./screens/MapScreen'));
const AdminScreen = loadable(() => import('./screens/AdminScreen'));
const ChatBox = loadable(() => import('./components/ChatBox'));
const Logo = loadable(() => import('./components/Logo'));
const Navigation = loadable(() => import('./components/Navigation'));

const GetCashScreen = loadable(() => import('./screens/GetCashScreen'));
//const TreasureScreen = loadable(() => import('./screens/TreasureScreen'));
const Overlay = loadable(() => import('./components/Overlay'));

function App() {
	const [isCashOpen, setCashOpen] = useState(false);
	//const [isTreasureOpen, setTreasureOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [sidebarSrc, setSidebarSrc] = useState('');
  const [renderFirst, setRenderFirst] = useState(false);
  const [isCookieOpen, setCookieOpen] = useState(true);
  const [isVerifiedOpen, setVerifiedOpen] = useState(true);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const hideOverlay = useSelector((state) => state.hideOverlay);
  const { overlayInfo } = hideOverlay;

  const cookieAgree = useSelector((state) => state.cookieAgree);
  const { cookieInfo } = cookieAgree;

  /*const firstVisit = useSelector((state) => state.firstVisit);
  const { visitInfo } = firstVisit;*/

  /*const modalBg = useSelector((state) => state.modalBg);
  const { modalInfo } = modalBg;*/
    
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkValidAccessToken());
    setRenderFirst(true);

    /*document.addEventListener("DOMContentLoaded", function() {
      var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
      if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                  videoSource.src = videoSource.dataset.src;
                }
              }
    
              console.log("Iam Shadow");
              video.target.load();
              video.target.classList.remove("lazy");
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });
    
        lazyVideos.forEach(function(lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    });*/
    
    const isMobilePhone = window.innerWidth <= 768; 
    setIsMobile(isMobilePhone);
    if(isMobilePhone) {
      overlayHandler(null, true);
    }
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [dispatch]);

  const sidebarHandler = (e, link) => {
    setSidebarSrc(link);
    setSidebarIsOpen(e);
  };

  const overlayHandler = (e, force) => {
    dispatch(saveHideOverlay(force ? true : e.target.checked));
  };

  const cookieHandler = () => {
    dispatch(saveCookieAgree(true));
  };

  const scrollHandler = () => {
    if (window.scrollY > 1) {
      if(window.innerWidth <= 910) {
        document.getElementById("nav").style.top = "-5px";
      }
      else {
        document.getElementById("nav").style.top = "-15px";
      }
      document.getElementById("nav-mobile").style.backgroundPositionY = "-20px";
      if(window.innerWidth <= 910) {
        document.getElementById("nav-mobile").style.setProperty('height', '50px', 'important');
      }
    } else {
      document.getElementById("nav").style.top = "0";
      document.getElementById("nav-mobile").style.backgroundPositionY = "0px";
      document.getElementById("nav-mobile").style.setProperty('height', '76px', 'important');
    }
  };

  return (
    <BrowserRouter>
      <div className="grid-container">
      <div className={renderFirst ? "triangle left open" : "triangle left"}><div className="border1"/><div className="border2"/><div className="border3"/><Logo/></div>
      <div className={renderFirst ? "triangle right open" : "triangle right"}><div className="border1"/><div className="border2"/><div className="border3"/><Logo/></div>
      {userInfo && userInfo.bVerifiedEmail !== true && isVerifiedOpen ? 
      <div className="verify-email">
      <div className="verify-email-close"><button type="button" className="clean-button" onClick={() => setVerifiedOpen(false)}><i className="fa fa-close"></i></button></div>
      <div className="verify-email-content">
        <p><i className="fa fa-info-circle"></i> In order to get full access to our services and to secure your account in the best possible way, you should verify your email. We sent an activation email to <b>{userInfo.email}</b>.</p>
        </div>
        </div> : ''}
      {cookieInfo !== true && isCookieOpen ? 
      <div>
        <div className="cookie-agreement-bst"><div className="cookie-agreement-content">
        <div className="cookie-background"></div>
        <div className="cookie-agreement-close"><button type="button" className="clean-button" onClick={() => setCookieOpen(false)} aria-label="X"><i className="fa fa-close"></i></button></div>
          <Logo/>
          <strong><p>We don't care about cookies</p></strong>
          <p>For your information we would like to inform you that we do not use cookies. We think cookies are simply outdated and only serve the purpose of collecting user information. Because we respect your privacy, we keep all data with you and store only the most necessary information.</p>
          <button type="button" className="cookie" onClick={cookieHandler} aria-label="Ok"><i className="fa fa-thumbs-up"></i></button>
          <p></p>
          </div>
          </div>
          </div> : ''}
          <div className={sidebarIsOpen ? 'asidebg open' : 'asidebg'}/>
          <AsideScreen Name='Discord' isMobile={isMobile} sidebarIsOpen={sidebarIsOpen} src={sidebarSrc} sidebarHandler={(e, link) => {sidebarHandler(e, link)}}/>
        <main className={sidebarIsOpen && overlayInfo !== true ? 'blur eyecandy' : sidebarIsOpen ? 'blur' : overlayInfo !== true  ? '' : 'eyecandy'}> 
        {overlayInfo !== true && <video autoPlay muted loop id="videobg"><source src="/images/bg.mp4" type="video/mp4"/></video>}
        {overlayInfo !== true && sidebarIsOpen !== true? <Overlay/> : ''}
        <div className="parent">
        <div id="nav-mobile" className="">
        <table id="nav">
          <thead>
            <tr>
              <td>{sidebarIsOpen ? 
              <button type="button" className="open-sidebar" aria-label="Close Sidebar" onClick={() => sidebarHandler(false, '')}><i className="fa fa-bars"></i></button>
              :
              <button type="button" className="open-sidebar" aria-label="Open Sidebar" onClick={() => sidebarHandler(true, 'https://discord.com/widget?id=537015467299176459&theme=dark')}><i className="fa fa-bars"></i></button>
              }
              </td>
              <td><Navigation cls="nav-links" dropdown="dropdown-content"/></td>
              </tr>
          </thead>
        </table>
        </div>
        </div>
        <Link to="/" aria-label="Home"><Logo cls="logo" isMobile={isMobile}/></Link>
        <div className="content">
          <Route path="/seller/:id" component={SellerScreen}/>
          <Route path="/cart/:id?" component={CartScreen}/>
          <Route path="/product/:id" component={ProductScreen} exact/>
          <Route path="/product/:id/edit" component={ProductEditScreen} exact/>
          <Route path="/news/:id" component={NewsScreen} exact/>
          <Route path="/news/:id/edit" component={NewsEditScreen} exact/>
          <Route path="/role/:id/edit" component={RoleEditScreen} exact/>
          <Route path="/signin" component={SigninScreen}/>
          <Route path="/register" component={RegisterScreen}/>
          <Route path="/forgotten-password" component={ForgottenPasswordScreen} exact/>
          <Route path="/forgotten-password/:id" component={ForgottenVerifyScreen} exact/>
          <Route path="/shipping" component={ShippingAddressScreen}/>
          <Route path="/payment" component={PaymentMethodScreen}/>
          <Route path="/placeorder" render={(props) => <PlaceOrderScreen {...props} isCashOpen={isCashOpen} setCashOpen={(e) => {setCashOpen(e)}}/>}/>
          <Route path="/order/:id" component={OrderScreen}/>
          <Route path="/orderhistory" component={OrderHistoryScreen}/>
          <Route path="/download" component={DownloadScreen} exact/>
          {/*<Route path="/wiki" component={WikiScreen} exact/>*/}
          <Route path="/verify/:id" component={VerifyScreen} exact/>
          <Route path="/ranking" component={AchievementScreen} exact/>
          <Route path="/ranking/mythic" component={RankingMythicScreen} exact/>
          <Route path="/ranking/achievements" component={AchievementScreen} exact/>
          <Route path="/ranking/elo" component={EloScreen} exact/>
          <Route path="/ranking/guilds" component={GuildsScreen} exact/>
          <Route path="/character/:name" component={CharacterScreen} exact/> 
          <Route path="/guild/:name" component={GuildScreen} exact/>
          <Route path="/itemmall" render={(props) => <ItemmallScreen {...props} isCashOpen={isCashOpen} setCashOpen={(e) => {setCashOpen(e)}}/>} exact/>
          <Route path="/checkout/verify/:orderToken" component={CheckoutVerifyScreen} exact/>
          <Route path="/checkout/verify/cancel" component={CheckoutVerifyScreen} exact/>
          <Route path="/privacy-policy" component={PrivacypolicyScreen} exact/>
          <Route path="/tos" component={TosScreen} exact/>
          <Route path="/" component={HomeScreen} exact/>

          <PrivateRoute path="/profile" component={ProfileScreen} isCashOpen={isCashOpen} setCashOpen={(e) => {setCashOpen(e)}}></PrivateRoute>
          {/*<PrivateRoute path="/map" component={MapScreen}></PrivateRoute>*/}

          <AdminRoute path="/admin" role="bCanViewDashboard" component={AdminScreen}/>
          <AdminRoute path="/redeemlist" role="bCanViewGiftCard" component={RedeemListScreen} />

          <SellerRoute path="/productlist/seller" component={ProductListScreen} />
          <SellerRoute path="/orderlist/seller" component={OrderListScreen}/>
          {isCashOpen && <GetCashScreen isCashOpen={isCashOpen} setCashOpen={(e) => {setCashOpen(e)}}/>}
          {/*{isTreasureOpen && <TreasureScreen isTreasureOpen={isTreasureOpen} setTreasureOpen={(e) => {setTreasureOpen(e)}}/>}*/}
          </div>
        </main>
        <footer className="row center">
          <div className="footer">
        {userInfo && userInfo.role && !userInfo.role.bIsAdmin && !userInfo.role.bIsSupport && <ChatBox userInfo={userInfo} />}
        <div className="hideSnow">
        <input type="checkbox" id="toggle" className="offscreen" name="hideeyecandy" aria-label="Hide Eyecandy" onChange={(e) => overlayHandler(e, false)} checked={overlayInfo !== true ? false : true}/>
        <label htmlFor="toggle" className="switch"></label>{'Hide Eyecandy'}
        </div>
          <div className="copyRight">
            Copyright © 2023 Euphresia FlyFF - All Rights Reserved. | Powered by <a href="https://discord.gg/DbGu67S">Deadlock#4551</a> | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/tos">Terms of Service</Link>
          </div>
          <div className="socialMedia">
          <div className="wrapper">
            <ul>
              <li className="facebook"><a href="https://www.facebook.com/euphresiaflyff" aria-label="Our official facebook page" target="_blank" rel="noreferrer"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
              <li className="twitter"><a href="https://twitter.com/Euphresia1" aria-label="Our official twitter page" target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
              <li className="instagram"><a href="https://www.instagram.com/euphresiaflyff/" aria-label="Our official instagram page" target="_blank" rel="noreferrer"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
              <li className="reddit"><a href="https://www.reddit.com/r/EuphresiaFlyFF/" aria-label="Our official reddit page" target="_blank" rel="noreferrer"><i className="fa fa-reddit-alien fa-2x" aria-hidden="true"></i></a></li>
              <li className="discord"><a href="https://discord.gg/DbGu67S" aria-label="Our official discord server" target="_blank" rel="noreferrer">
              <svg width="24" height="24" fill="currentColor" className="fa-discord" viewBox="0 0 16 16">
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/></svg>
                </a></li>
            </ul>
          </div>
          </div>
          
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
