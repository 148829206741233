import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { cartReducer } from './reducers/cartReducers';
import {
  orderCreateReducer,
  paypalCreateReducer,
  orderDeleteReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderListReducer,
  orderMineListReducer,
  orderPayReducer,
  orderSummaryReducer,
} from './reducers/orderReducers';
import {
  productCategoryListReducer,
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  dpListReducer,
  productReviewCreateReducer,
  productUpdateReducer,
} from './reducers/productReducers';
import {
  newsCreateReducer,
  newsDeleteReducer,
  newsDetailsReducer,
  newsListReducer,
  newsReviewCreateReducer,
  newsUpdateReducer,
} from './reducers/newsReducers';
import {
  roleCreateReducer,
  roleDeleteReducer,
  roleDetailsReducer,
  roleListReducer,
  roleUpdateReducer,
} from './reducers/roleReducers';
import {
  userAddressMapReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userRegisterReducer,
  userSigninReducer,
  //userTopSellerListReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
  userUpdateNotificationReducer,
  resendMailReducer,
  sendRedeemCodeReducer,
} from './reducers/userReducers';
import {
  hideOverlayReducer,
  cookieAgreeReducer,
  firstVisitReducer
  //setModalReducer,
} from './reducers/localReducers';
import {
  accessTokenExpReducer,
  accessTokenReducer,
  refreshTokenReducer,
  verifyTokenReducer,
} from './reducers/tokenReducers';

import {
  accountListReducer,
  accountEditReducer,
  accountMaintenanceReducer,
  accountGetReducer,
} from './reducers/flyffAccountReducers';
import {
  characterListReducer,
  characterDetailsReducer,
  characterUpdateReducer,
  characterDeleteReducer,
  mythicListReducer,
} from './reducers/flyffCharacterReducers';
import {
  guildListReducer,
  guildDetailsReducer,
  gsLogReducer,
  guildWarReducer,
} from './reducers/flyffGuildReducers';
import { itemListReducer } from './reducers/flyffItemReducers';
import {
  downloadCreateReducer,
  downloadDeleteReducer,
  downloadDetailsReducer,
  downloadReducer,
  downloadUpdateReducer,
} from './reducers/downloadReducers';
import { wikiArtCreateReducer, wikiArtDeleteReducer, wikiArtReducer, wikiArtUpdateReducer, wikiCatCreateReducer, wikiCatDeleteReducer, wikiCatReducer, wikiCatUpdateReducer, wikiReviewCreateReducer } from './reducers/wikiReducers';
import { milestoneClaimReducer } from './reducers/milestoneReducers';
import { voteGetLinkReducer } from './reducers/voteReducers';
import { uploadedFileReducer } from './reducers/uploadReducers';
import { paypalVerifyReducer } from './reducers/paypalReducers';
import { redeemCodeCreateReducer, redeemCodeDeleteReducer, redeemCodeListReducer, redeemCodeUpdateReducer } from './reducers/redeemCodeReducers';
import { auctionsListReducer, banksListReducer, guildbanksListReducer, itemlookupListReducer, mailsListReducer, pocketsListReducer, tradesListReducer, vendorsListReducer } from './reducers/flyffMiscReducers';

const initialState = {
  userSignin: { userInfo: null },
  refreshToken: { refreshTokenInfo: localStorage.getItem('tok')
  ? JSON.parse(localStorage.getItem('tok'))
  : null
  },
  accessToken: { accessTokenInfo: null },
  accessTokenExp: { accessTokenExpInfo: null },
  cart: {
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : {},
    paymentMethod: 'PayPal',
  },
  hideOverlay: {
    overlayInfo: localStorage.getItem('hideOverlay')
    ? JSON.parse(localStorage.getItem('hideOverlay'))
    : {},
  },
  cookieAgree: {
    cookieInfo: localStorage.getItem('cookieAgree')
    ? JSON.parse(localStorage.getItem('cookieAgree'))
    : {},
  },
  firstVisit: {
    visitInfo: localStorage.getItem('firstVisit')
    ? JSON.parse(localStorage.getItem('firstVisit'))
    : {},
  },
};
const reducer = combineReducers({
  productList: productListReducer,
  dpList: dpListReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  productCategoryList: productCategoryListReducer,
  productReviewCreate: productReviewCreateReducer,

  redeemCodeList: redeemCodeListReducer,
  redeemCodeCreate: redeemCodeCreateReducer,
  redeemCodeUpdate: redeemCodeUpdateReducer,
  redeemCodeDelete: redeemCodeDeleteReducer,

  newsList: newsListReducer,
  newsDetails: newsDetailsReducer,
  newsCreate: newsCreateReducer,
  newsUpdate: newsUpdateReducer,
  newsDelete: newsDeleteReducer,
  newsReviewCreate: newsReviewCreateReducer,

  roleList: roleListReducer,
  roleDetails: roleDetailsReducer,
  roleCreate: roleCreateReducer,
  roleUpdate: roleUpdateReducer,
  roleDelete: roleDeleteReducer,

  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  orderCreate: orderCreateReducer,
  paypalCreate: paypalCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderMineList: orderMineListReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  resendMail: resendMailReducer,
  
  sendRedeemCode: sendRedeemCodeReducer,

  userUpdateNotifications: userUpdateNotificationReducer,
  userUpdate: userUpdateReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  orderDeliver: orderDeliverReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  //userTopSellersList: userTopSellerListReducer,
  userAddressMap: userAddressMapReducer,
  orderSummary: orderSummaryReducer,

  hideOverlay: hideOverlayReducer,
  cookieAgree: cookieAgreeReducer,
  firstVisit: firstVisitReducer,
  //modalBg: setModalReducer,

  refreshToken: refreshTokenReducer,
  accessToken: accessTokenReducer,
  accessTokenExp: accessTokenExpReducer,
  verifyToken: verifyTokenReducer,

  accountList: accountListReducer,
  accountEdit: accountEditReducer,
  accountGet: accountGetReducer,
  accountMaintenance: accountMaintenanceReducer,

  mailsList: mailsListReducer,
  banksList: banksListReducer,
  guildbanksList: guildbanksListReducer,
  pocketsList: pocketsListReducer,
  vendorsList: vendorsListReducer,
  auctionsList: auctionsListReducer,
  tradesList: tradesListReducer,
  itemlookupList: itemlookupListReducer,

  characterList: characterListReducer,
  characterDetails: characterDetailsReducer,
  characterDelete: characterDeleteReducer,
  characterUpdate: characterUpdateReducer,

  mythicList: mythicListReducer,

  itemList: itemListReducer,

  guildList: guildListReducer,
  guildWar: guildWarReducer,
  gsLog: gsLogReducer,
  guildDetails: guildDetailsReducer,

  downloadList: downloadReducer,
  downloadDetails: downloadDetailsReducer,
  downloadCreate: downloadCreateReducer,
  downloadUpdate: downloadUpdateReducer,
  downloadDelete: downloadDeleteReducer,

  wikiCatList: wikiCatReducer,
  wikiCatCreate: wikiCatCreateReducer,
  wikiCatUpdate: wikiCatUpdateReducer,
  wikiCatDelete: wikiCatDeleteReducer,

  wikiArtList: wikiArtReducer,
  wikiArtCreate: wikiArtCreateReducer,
  wikiArtUpdate: wikiArtUpdateReducer,
  wikiArtDelete: wikiArtDeleteReducer,

  wikiReviewCreate: wikiReviewCreateReducer,

  milestoneClaim: milestoneClaimReducer,

  voteLink: voteGetLinkReducer,
  uploadedFile: uploadedFileReducer,

  paypalVerify: paypalVerifyReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
