export const WIKI_CAT_LIST_REQUEST = 'WIKI_CAT_LIST_REQUEST';
export const WIKI_CAT_LIST_SUCCESS = 'WIKI_CAT_LIST_SUCCESS';
export const WIKI_CAT_LIST_FAIL = 'WIKI_CAT_LIST_FAIL';

export const WIKI_CAT_DETAILS_REQUEST = 'WIKI_CAT_DETAILS_REQUEST';
export const WIKI_CAT_DETAILS_SUCCESS = 'WIKI_CAT_DETAILS_SUCCESS';
export const WIKI_CAT_DETAILS_FAIL = 'WIKI_CAT_DETAILS_FAIL';
export const WIKI_CAT_DETAILS_RESET = 'WIKI_CAT_DETAILS_RESET';

export const WIKI_CAT_CREATE_REQUEST = 'WIKI_CAT_CREATE_REQUEST';
export const WIKI_CAT_CREATE_SUCCESS = 'WIKI_CAT_CREATE_SUCCESS';
export const WIKI_CAT_CREATE_FAIL = 'WIKI_CAT_CREATE_FAIL';
export const WIKI_CAT_CREATE_RESET = 'WIKI_CAT_CREATE_RESET';

export const WIKI_CAT_UPDATE_REQUEST = 'WIKI_CAT_UPDATE_REQUEST';
export const WIKI_CAT_UPDATE_SUCCESS = 'WIKI_CAT_UPDATE_SUCCESS';
export const WIKI_CAT_UPDATE_FAIL = 'WIKI_CAT_UPDATE_FAIL';
export const WIKI_CAT_UPDATE_RESET = 'WIKI_CAT_UPDATE_RESET';

export const WIKI_CAT_DELETE_REQUEST = 'WIKI_CAT_DELETE_REQUEST';
export const WIKI_CAT_DELETE_SUCCESS = 'WIKI_CAT_DELETE_SUCCESS';
export const WIKI_CAT_DELETE_FAIL = 'WIKI_CAT_DELETE_FAIL';
export const WIKI_CAT_DELETE_RESET = 'WIKI_CAT_DELETE_RESET';

export const WIKI_ART_LIST_REQUEST = 'WIKI_ART_LIST_REQUEST';
export const WIKI_ART_LIST_SUCCESS = 'WIKI_ART_LIST_SUCCESS';
export const WIKI_ART_LIST_FAIL = 'WIKI_ART_LIST_FAIL';

export const WIKI_ART_DETAILS_REQUEST = 'WIKI_ART_DETAILS_REQUEST';
export const WIKI_ART_DETAILS_SUCCESS = 'WIKI_ART_DETAILS_SUCCESS';
export const WIKI_ART_DETAILS_FAIL = 'WIKI_ART_DETAILS_FAIL';
export const WIKI_ART_DETAILS_RESET = 'WIKI_ART_DETAILS_RESET';

export const WIKI_ART_CREATE_REQUEST = 'WIKI_ART_CREATE_REQUEST';
export const WIKI_ART_CREATE_SUCCESS = 'WIKI_ART_CREATE_SUCCESS';
export const WIKI_ART_CREATE_FAIL = 'WIKI_ART_CREATE_FAIL';
export const WIKI_ART_CREATE_RESET = 'WIKI_ART_CREATE_RESET';

export const WIKI_ART_UPDATE_REQUEST = 'WIKI_ART_UPDATE_REQUEST';
export const WIKI_ART_UPDATE_SUCCESS = 'WIKI_ART_UPDATE_SUCCESS';
export const WIKI_ART_UPDATE_FAIL = 'WIKI_ART_UPDATE_FAIL';
export const WIKI_ART_UPDATE_RESET = 'WIKI_ART_UPDATE_RESET';

export const WIKI_ART_DELETE_REQUEST = 'WIKI_ART_DELETE_REQUEST';
export const WIKI_ART_DELETE_SUCCESS = 'WIKI_ART_DELETE_SUCCESS';
export const WIKI_ART_DELETE_FAIL = 'WIKI_ART_DELETE_FAIL';
export const WIKI_ART_DELETE_RESET = 'WIKI_ART_DELETE_RESET';

export const WIKI_REVIEW_CREATE_REQUEST = 'WIKI_REVIEW_CREATE_REQUEST';
export const WIKI_REVIEW_CREATE_SUCCESS = 'WIKI_REVIEW_CREATE_SUCCESS';
export const WIKI_REVIEW_CREATE_FAIL = 'WIKI_REVIEW_CREATE_FAIL';
export const WIKI_REVIEW_CREATE_RESET = 'WIKI_REVIEW_CREATE_RESET';